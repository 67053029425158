.padding-0{
  padding: 0;
}

.padding-h-0{
  padding-left: 0;
  padding-right: 0;
}

.padding-left-0{
  padding-left: 0;
}

.padding-right-0{
  padding-right: 0;
}

.padding-top-0{
  padding-top: 0;
}

.padding-bottom-0{
  padding-bottom: 0;
}

.padding-v-0{
  padding-top: 0;
  padding-bottom: 0;
}