.close-icon, .close-icon-hover {
  color: $themeColor;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.close-icon-wrapper {
  .close-icon {
    display: block;
  }
  .close-icon-hover {
    display: none;
  }
  &:hover {
    .close-icon {
      display: none;
    }
    .close-icon-hover {
      display: block;
    }
  }
}