button{
  &.btn-theme{
    &.btn-medium{
      border-radius: ($sizeMedium)/2;
      height: $sizeMedium;
      padding: $sizeSmall/2 $sizeSmall*2;
      font-size: 1em;
    }
    background-color: transparent;
    color: $themeColor;
    border: $borderSmall solid $themeColor;
    &.inverse{
      color: $themeWhite;
      background-color: $themeColor;
      &:hover{
        color: $themeColor;
        border-color: $themeColor;
        background-color: $themeWhite;
      }
    }
    border-radius: $sizeMedium/2;
    height: $sizeMedium ;
    padding: 0 $sizeSmall*2;
    font-weight: normal;
    box-shadow: none;
    font-size: 1.2em;
    text-align: center;
    @media #{$sm}{
      font-size: .8em;
      border-radius: ($sizeMedium - $sizeSmall)/2;
      height: $sizeMedium - $sizeSmall;
    }
    &:focus{
      outline: none;
      box-shadow: none;
      color: $themeColor;
    }
    &:hover, &.active, &:active{
      color: $themeWhite;
      background-color: $themeColor;
    }
    &:last-of-type{
      margin-right: 0;
    }
    &.unit-button{
      padding-left: $sizeSmall*1.5;
      padding-right: $sizeSmall*1.5;
      margin: $sizeSmall $sizeSmall/2;
      @media #{$sm}{
        padding-left: $sizeSmall;
        padding-right: $sizeSmall;
        margin: 0 $sizeSmall/2;
      }
      &[disabled]{
        color: $themeDarkGray;
        border-color: $themeDarkGray;
        cursor: default;
        &:hover{
          color: $themeDarkGray;
          border-color: $themeDarkGray;
          background-color: transparent;
        }
      }
    }
    &.btn-delete-all{
      float: right;
      margin-right: 15px;
    }
  }
}