.search-field{
  position: absolute;
  top: $sizeSmall;
  right: $sizeSmall*2;
  width: calc(100% - #{$sizeSmall * 4});
  @media #{$sm}{
    width: 250px;
    right: $sizeMedium;
  }
  input{
    background-color: $themeWhite;
    height: $sizeMedium;
    border: $borderSmall solid $themeBorderGray;
    border-radius: $sizeMedium/2;
    padding: $sizeSmall;
    width: 100%;

    &:hover{
      border-color: $themeColor;
    }
    &:active, &:focus{
      outline: none;
      border-color: $themeColor;
    }
  }
  .icon-search{
    position: absolute;
    color: $themeColor;
    right: $sizeSmall;
    top: 8px;
    cursor: pointer;
  }
}