.toaster-container {
  position: fixed;
  z-index: 999999999 !important;
  right: -1px !important;
  bottom: 10px !important;
  top: initial !important;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-end);
  .toaster {
    background-color: $themeLightGray !important;
    color: $themeColor !important;
    border: $borderSmall solid $themeColor;
    box-shadow: none !important;
    padding: $sizeMedium !important;
    margin-bottom: $sizeSmall*2 !important;
    width: 250px !important;
    &.danger {
      background-color: $themeColor !important;
      color: $themeWhite !important;
      border-color: $themeWhite;
    }
  }
}