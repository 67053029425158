.sub-navigation {
  ul {
    margin-top: $sizeMedium;
    text-align: right;
    list-style: none;
    padding: 0;
    font-size: .85em;
    @media #{$sm}{
      font-size: 1em;
      margin-top: $sizeSmall / 2;
    }
    li {
      display: inline;
      margin: $sizeSmall;
      a, span{
        color: $themeBlack;
      }
      &.active, &:hover {
        a, span{
          color: $themeColor
        }
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}