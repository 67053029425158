.navigation {
  position: fixed;
  background-color: $themeColor;
  color: $themeWhite;
  height: 100vh;
  width: 50px;
  padding: $sizeSmall;
  overflow-y: scroll;

  .logo-desktop {
    display: none;
    filter: brightness(100);
  }
  .logo-mobile {
    display: block;
    filter: brightness(100);
  }
  @media #{$sm}{
    width: 230px;
    padding: $sizeSmall * 2;
    .logo-desktop {
      display: block;
      max-width: 100%;
    }
    .logo-mobile {
      display: none;
    }
  }

  nav {
    margin-top: $sizeMedium;
    @media #{$sm}{
      margin-top: $sizeLarge;
    }
    ul {
      padding: 0;
      hr {
        margin: $sizeSmall*2 0 $sizeSmall*2 $sizeMedium;
        opacity: .5;
      }
      @media #{$sm}{
        padding: 0 0 $sizeLarge;
      }
      list-style: none;
      text-align: center;
      margin-left: -$sizeMedium;
      li {
        padding-right: 0;
        padding-left: $sizeMedium;
        @include semi-rounded-div($sizeMedium+5, $borderSmall, $sizeSmall);
        border-color: $themeColor;
        @media #{$sm}{
          @include semi-rounded-div($sizeLarge, $borderSmall);
          border-color: $themeColor;
        }
        a {
          color: $themeWhite;
          font-size: .8em;
          line-height: 3.6em;
          @media #{$sm}{
            font-size: 1.3em;
            line-height: 2.4em;
          }
        }
        &:hover{
          border-color: $themeWhite;
          cursor: pointer;
        }
        &.active{
          border-color: $themeWhite;
          background-color: $themeWhite;
          a{
            color: $themeColor;
          }
        }
        .nav-label{
          display: none;
        }
        .nav-icon{
          display: block;
        }
        @media #{$sm}{
          .nav-label{
            display: block;
          }
          .nav-icon{
            display: none;
          }
        }
      }
    }
  }

  .nav-footer {
    position: absolute;
    bottom: $sizeSmall;
    text-align: center;
    width: 100%;
    margin-left: -$sizeSmall * 2;
    margin-right: -$sizeSmall * 2;
    display: none;
    @media #{$sm}{
      display: block;
    }
    a{
      color: white;
    }
  }
}