::selection {
  text-shadow: none;
  background-color: $themeColor;
  color: $themeWhite;
}

::-moz-selection {
  text-shadow: none;
  background-color: $themeColor;
  color: $themeWhite;
}
