@font-face {
  font-family: 'Segoe UI Semilight';
  src: url('../fonts/SegoeUI-Semilight.eot');
  src: url('../fonts/SegoeUI-Semilight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SegoeUI-Semilight.woff') format('woff'),
  url('../fonts/SegoeUI-Semilight.ttf') format('truetype'),
  url('../fonts/SegoeUI-Semilight.svg#Segoe UI Semilight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Light';
  src: url('../fonts/SegoeUI-Light.eot');
  src: url('../fonts/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SegoeUI-Light.woff') format('woff'),
  url('../fonts/SegoeUI-Light.ttf') format('truetype'),
  url('../fonts/SegoeUI-Light.svg#SegoeUI-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@import "variables";
@import "base";
@import "boxes";
@import "close-button";
@import "toaster";
@import "spinner";
@import "select";
@import "buttons";
@import "barcode";
@import "datepicker";
@import "pagination";

@import "content/auth";
@import "content/navigation";
@import "content/main-page";
@import "content/header";
@import "content/search";
@import "content/subnavigation";
@import "content/profile";
@import "content/react-confirm-alert";
@import "content/empty";
@import "content/controls-row";
@import "content/session-info";

@import "helpers/centering";
@import "helpers/dimension";
@import "helpers/margins";
@import "helpers/paddings";
@import "helpers/scrollbar";
@import "helpers/selection";
@import "helpers/animations";
@import "helpers/placeholder";
@import "helpers/checkboxes";
