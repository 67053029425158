.session-info-outer{
  position: fixed;
  z-index: 999;
  top: -50px;
  left: 50%;
  display: none;
  @media #{$sm}{
    display: block;
  }
  &.active{
    top: 0px;
  }
  .session-info-inner{
    left: -50%;
    position: relative;
    text-align: center;
    background-color: $themeColor;
    padding: 5px $sizeMedium 7px $sizeMedium;
    color: $themeWhite;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 1em;
    letter-spacing: .05em;
    &.inactive{
      background-color: $themeDarkGray;
    }
    span{
      font-weight: bold;
      padding-left: 5px;
    }
  }
}