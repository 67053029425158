.auth-form {
  margin-top: -$sizeMedium;
  @media #{$sm}{
    margin-top: -100px;
  }
  width: 100%;
  h1 {
    font-size: 2em;
    padding-left: $sizeSmall;
    @media #{$sm}{
      padding-left: $sizeLarge;
    }
    margin-bottom: $sizeMedium;
  }
  button {
    background-color: $themeWhite;
    border: $borderSmall solid $themeColor;
    margin-left: -$borderSmall;
    border-top-left-radius: 0;
    border-top-right-radius: $sizeLarge/2;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $sizeLarge/2;
    height: $sizeLarge;
    width: 95%;
    color: $themeColor;
    text-transform: uppercase;
    font-size: 1.7em;
    text-align: left;
    padding-left: $sizeSmall;
    &:hover {
      color: $themeWhite;
      background-color: $themeColor;
    }
    @media #{$sm}{
      padding-left: $sizeLarge;
    }
    @media #{$md}{
      width: 100%;
    }
    @media #{$lg}{
      width: 100%;
    }
    @media #{$xlg}{
      width: 95%;
    }
  }
  .button-row {
    width: 100%;
    margin-top: $sizeLarge;
    @media #{$md}{
      margin-top: $sizeMedium;
    }
  }
  input {
    border-radius: 0;
    height: $sizeMedium + 20;
    border: $borderSmall solid $themeDarkGray;
    border-left: 0;
    border-right: 0;
    background-color: $themeLightGray;
    margin-top: -$borderSmall;
    &:first-child {
      margin-top: $borderSmall;
    }
    padding-left: $sizeLarge;
    position: relative;
  }
  .button-side-text {
    height: $sizeMedium + 20;
    padding: 15px 0;
    a {
      color: $themeColor;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .input-icon {
    color: $themeColor;
    position: absolute;
    left: 10px;
    z-index: 1;
    margin-top: 10px;
  }
}