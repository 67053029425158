.react-confirm-alert-blur {
  -webkit-animation: react-confirm-alert-blurify 0.4s forwards;
  -moz-animation: react-confirm-alert-blurify 0.4s forwards;
  -o-animation: react-confirm-alert-blurify 0.4s forwards;
  animation: react-confirm-alert-blurify 0.4s forwards;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.6);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.8);
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert {}

.react-confirm-alert-body {
  width: 400px;
  padding: 30px;
  text-align: center;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: $themeColor;
}

.react-confirm-alert-svg{
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  color: $themeBlack;
  font-size: 30px;
  margin-bottom: $sizeMedium;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: $sizeMedium;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background-color: $themeWhite;
  border: none;
  display: inline-block;
  padding: 0 $sizeMedium;
  color: $themeColor;
  margin: 0 $sizeMedium;
  font-size: 1em;
  line-height: 1.2em;
  cursor: pointer;
  border: $borderSmall solid $themeColor;
  height: $sizeMedium;
  border-radius: $sizeMedium/2;
  &:hover{
    background-color: $themeColor !important;
    color: $themeWhite !important;
    border-color: $themeColor !important;
  }
  &:first-child{
    color: $themeDarkGray;
    border-color: $themeDarkGray;
  }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes react-confirm-alert-blurify{
  from {
    filter: blur(0);
    -webkit-filter: blur(0);
  }
  to {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

@-moz-keyframes react-confirm-alert-blurify{
  from {
    filter: blur(0);
    -webkit-filter: blur(0);
  }
  to {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

@-o-keyframes react-confirm-alert-blurify{
  from {
    filter: blur(0);
    -webkit-filter: blur(0);
  }
  to {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

@keyframes react-confirm-alert-blurify{
  from {
    filter: blur(0);
    -webkit-filter: blur(0);
  }
  to {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}
