
::-webkit-scrollbar {
  width: 6px;
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  width: 7px !important;
  background-color: #EEEEEE;
}

::-webkit-scrollbar-track {
  border: 6px;
}

body::-webkit-scrollbar-track {
  border: none;
}

::-webkit-scrollbar-thumb {
  background-color: #424242 !important;
  border-radius: 0px !important;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333 !important;
}

::-webkit-scrollbar-thumb:active {
  background-color: #616161 !important;
}

.hidden-scrollbar{

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border: none;
  }

  &::-webkit-scrollbar-track {
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 0 !important;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: transparent !important;
  }
}
