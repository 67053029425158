.react-select-container{
  height: 34px;
  padding: 6px 12px 6px 0;
  margin-right: 5px;
  background: $themeWhite;
  &:hover{
    background: $themeLightGray;
  }
  .react-select__indicators{
    display: none;
  }
  .react-select__control{
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    font-size: 14px;
    color: #222f3e;
    -webkit-appearance: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    &--is-focused{
      outline: none;
      border: 0;
      box-shadow: none;
    }
  }
  .react-select__placeholder{
    font-style: italic;
  }
  .react-select__value-container{
    padding: 0;
  }
  .css-rsyb7x{
    margin: 0;
  }
  .react-select__menu{
    top: 100%;
    background-color: $themeWhite;
    border-radius: 0;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 -3px 6px 0 rgba(0, 0, 0, 0.07);
    margin-bottom: 8px;
    position: absolute;
    width: calc(100% + 14px);
    z-index: 5;
    box-sizing: border-box;
    margin-top: 30px;
    left: -14px;
    .react-select__option{
      background-color: $themeWhite;
      cursor: pointer;
      &:hover{
      background-color: $themeLightGray;
      }
      &--is-focused{
        background-color: $themeLightGray;
      }
      &--is-selected{
        color: $themeColor;
      }
    }
  }
}
