.spinner {
  opacity: 0;
  height: 0;
  transition: all 400ms ease-in-out;
  margin: 0 auto;

  width: $sizeLarge * 2;
  text-align: center;

  & > div {
    width: 17px;
    height: 17px;
    background-color: $themeColor;
    margin: $sizeMedium 3px 0 3px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  &.active{
    opacity: 1;
    height: 100px;
    margin: $sizeMedium auto;
    transition: all 400ms ease-in-out;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}