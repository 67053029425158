.pagination {
  margin: 30px auto;
  display: block;
  padding: 0 -15px;
  text-align: center;
  & > li {
    & > a {
      float: none;
      padding: 7px;
      height: $sizeMedium + $sizeSmall;
      width: $sizeMedium + $sizeSmall;
      text-align: center;
      display: inline-block;
      border-radius: 0 !important;
      border: none;
      color: $themeBlack;
      &:hover{
        background-color: $themeColor;
        font-weight: bold;
        color: $themeWhite;
      }
      outline: none;
      &:focus, &:active{
        background-color: transparent;
        color: $themeBlack;
      }
    }
    margin: 0 5px;
    &.active{
      a{
        background-color: $themeColor;
        font-weight: bold;
        color: $themeWhite;
        &:hover, &:focus, &:active{
          background-color: $themeColor;
        }
      }
    }
  }
}