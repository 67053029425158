.box-full {
  height: 30vh;
  @media #{$md}{
    height: 100vh;
  }
}

.box-full-supplement {
  height: 60vh;
  @media #{$md}{
    height: 100vh;
  }
}

.box-theme {
  background-color: $themeColor;
  color: $themeWhite;
  .box-theme-inner {
    img {
      filter: brightness(100);
      width: 40%;
      display: block;
      margin: auto;
      @media #{$md}{
        width: 80%;
      }
    }
    h2 {
      margin-top: $sizeSmall;
      text-align: center;
      font-size: 1.3em;
      line-height: 1.3em;
      padding: $sizeSmall $sizeLarge;
      @media #{$sm}{
        font-size: 1.9em;
      }
      @media #{$md}{
        padding: $sizeSmall $sizeLarge*2;
        margin-top: $sizeLarge;
      }
    }
    &.has-text {
      img {
        width: 20%;
        margin-top: 0;
        @media #{$md}{
          margin-top: - $sizeLarge*2;
        }
      }
    }
  }
}

.box {
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  position: relative;
  margin-bottom: $sizeMedium;
  background-color: $themeWhite;
  @include theme-box-shadow($themeLightGrayRGBA);

  &:not(.no-hover):hover, &.active {
    @include theme-box-shadow($themeColorRGBA);
  }

  &.vertical {
    .box-content {
      width: 100%;
      padding-bottom: 70px;
      @media #{$sm}{
        width: calc(100% - #{$sizeLarge + $sizeMedium});
        padding-bottom: $sizeSmall * 1.5;
      }
    }
    .box-icons-controls {
      width: 100%;
      @media #{$sm}{
        width: $sizeLarge + $sizeMedium;
      }
    }
    .box-controls {
      width: 100%;
      @media #{$sm}{
        width: $sizeLarge + $sizeMedium;
      }
    }
    .box-icons {
      width: 100%;
      @media #{$sm}{
        width: $sizeLarge + $sizeMedium;
      }
    }
  }

  &.multiple{
    .box-content {
      width: 100%;
      padding-bottom: $sizeMedium + ($sizeLarge * 2);
      @media #{$sm}{
        width: calc(100% - #{($sizeLarge*2) + ($sizeSmall*2)});
        padding-bottom: $sizeSmall * 1.5;
      }
      @media #{$xlg}{
        width: calc(100% - #{($sizeLarge*2) + ($sizeMedium*2)});
        padding-bottom: $sizeSmall * 1.5;
      }
    }
    .box-icons-controls {
      width: 100%;
      height: $sizeSmall + ($sizeLarge * 2);
      @media #{$sm}{
        width: ($sizeLarge*2) + ($sizeSmall*2);
        height: 100%;
      }
      @media #{$xlg}{
        width: ($sizeLarge*2) + ($sizeMedium*2);
        height: 100%;
      }
    }
    .box-controls {
      width: 100%;
      @media #{$sm}{
        width: $sizeLarge + $sizeSmall;
      }
      @media #{$xlg}{
        width: $sizeLarge + $sizeMedium;
      }
    }
    .box-icons {
      font-size: .75em;
      width: 100%;
      @media #{$sm}{
        width: $sizeLarge + $sizeSmall;
      }
      @media #{$xlg}{
        width: $sizeLarge + $sizeMedium;
      }
    }
  }

  .box-icons-controls{
    position: absolute;
    color: $themeWhite;
    @media #{$sm}{
      height: 100%;
      top: 0;
    }
    height: $sizeLarge;
    top: initial;
    bottom: 0;
    right: 0;
    @include flex-direction(column);
    @media #{$sm}{
     @include flex-direction(row);
    }
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-around);
    @include align-items(center);
  }

  .box-icons{
    color: $themeDarkGray;
    &.danger, &.warning {
      color: $themeColor;
    }
    @include flex-direction(row);
    height: 100%;
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-around);
    @include align-items(center);
  }

  .box-controls {
    a {
      text-decoration: none;
      color: $themeWhite;
    }
    background-color: $themeColor;
    @include flex-direction(row);
    @media #{$sm}{
      height: 100%;
      @include flex-direction(column);
    }
    height: 100%;
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-around);
    @include align-items(center);

    div {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .box-content {
    padding: $sizeSmall * 1.5;
    position: relative;
    h3 {
      margin-top: 0;
      margin-bottom: $sizeSmall/5;
      padding: 0 0 $sizeSmall/2 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    small {
      color: $themeDarkGray;
    }
    .box-content-inner {
      margin-top: $sizeSmall;
    }
    span {
      &.number {
        color: $themeDarkGray;
        &.highlight {
          color: $themeColor;
        }
        font-size: 1.8em;
      }
      &.label {
        color: $themeDarkGray;
      }
    }
    .ingredients-list {
      margin-top: $sizeSmall;
      padding: 0 $sizeSmall;
      > div {
        @include flexbox();
        margin: 5px 0;
      }
      &.large {
        height: auto;
      }
      &.medium {
        height: initial;
        @media #{$sm}{
          height: 165px;
        }

      }
      .values {
        width: 30%;
      }
      span.number {
        font-size: 1.6em;
      }
      .labels {
        width: 70%;
        color: $themeBlack;
        position: relative;
        span {
          &.label {
            position: absolute;
            bottom: 5px;
            color: $themeBlack;
            font-size: 90%;
          }
        }
      }
      span {
        &.unit {
          color: $themeDarkGray;
        }
      }
    }
    .box-inputs {
      &:not(.no-flex) {
        @media #{$sm}{
          @include flexbox();
        }
      }
      input {
        border-radius: 0;
        border: 0;
        padding-left: 0;
        color: $themeBlack;
        box-shadow: none;
        &:hover {
          background-color: $themeLightGray;
        }
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
      > div {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        &.ingredient-numeric {
          flex-grow: .55;
        }
      }
      textarea{
        margin-top: $sizeSmall !important;
        resize: vertical;
        width: 100% !important;
      }
    }
    .reset-box {
      position: absolute;
      top: $sizeSmall;
      right: $sizeSmall;
      color: $themeDarkGray;
      font-size: 2em;
      cursor: pointer;
      z-index: 5;
      &:hover {
        color: $themeColor;
      }
    }
  }
}

.box-ingredients {
  .ingredients-row {
    @media #{$sm}{
      @include flexbox();
    }
    .ingredient-name {
      padding-left: 0;
      width: 100%;
      @media #{$sm}{
        padding-left: $sizeSmall;
        width: 30%;
      }
      font-size: 14px;
      line-height: 1.42857143;
      span {
        height: 34px;
        padding: 6px 12px 6px 0;
        display: block;
      }
    }
    .remove-ingredient {
      button {
        margin: $sizeMedium auto;
        width: 100%;
        @media #{$sm}{
          width: auto;
          margin: $sizeSmall/2 0 0 $sizeLarge;
        }
      }
    }
  }
}

.ingredient-units {
  margin: $sizeMedium 0 $sizeSmall 0;
  @media #{$sm}{
    margin: $sizeSmall/2 0 0;
  }
}

.box-content {
  .purchase-detail-header {
    @include flexbox();
    & > div {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
    .purchase-detail-header-info {
      color: $themeDarkGray;
      font-size: 1.3em;
      span {
        color: $themeColor;
        font-size: 1.2em;
      }
    }
  }
  .purchase-detail-content {
    margin-top: $sizeMedium;
  }
}

.order-box{
  .order-header{
    margin-bottom: 5px;
  }
  .order-heading{
    width: calc(100% - 45px);
    float: left;
    small{
      height: 50px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .order-icon{
    width: 45px;
    float: right;
  }
  .meal-order{
    margin-top: $sizeSmall;
    .order-controls-outer{
      height: 40px;
      @media #{$sm}{
        height: 30px;
      }
      margin-top: 5px;
      div{
        height: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        background-color: $themeColor;
        color: $themeWhite;
        cursor: pointer;
      }
    }
  }
  .box-controls{
    margin: 15px -15px -15px;
    padding: 10px 0;
    color: $themeWhite;
  }
}

