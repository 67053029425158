.margin-0{
  margin: 0;
}

.margin-h-0{
  margin-left: 0;
  margin-right: 0;
}

.margin-left-0{
  margin-left: 0;
}

.margin-right-0{
  margin-right: 0;
}

.margin-top-0{
  margin-top: 0;
}

.margin-bottom-0{
  margin-bottom: 0;
}

.margin-v-0{
  margin-top: 0;
  margin-bottom: 0;
}