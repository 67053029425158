.DayPickerInput{
  input{
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    font-size: 14px;
    color: #222f3e;
    -webkit-appearance: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    height: 34px;
    line-height: 1.42857143;
  }
}