.header{
  width: 100%;
  //height: $sizeLarge * 2.5;
  @media #{$sm}{
    height: $sizeLarge * 2;
    padding: 0 $sizeMedium;
  }
  background-color: $themeLightGray;
  border-bottom: $borderSmall solid $themeBorderGray;
  position: relative;
  padding: 0 $sizeSmall*2;
  h1{
    font-size: 1.1em;
    margin: 0 0 -5px 0;
    padding-top: $sizeLarge + $sizeSmall;
    @media #{$sm}{
      font-size: 1.2em;
      padding-top: $sizeMedium + $sizeSmall;
    }
    span{
      &:first-of-type{
        color: $themeColor;
        font-size: 1.3em;
        @media #{$sm}{
          font-size: 1.5em;
        }
      }
    }
    svg{
      color: $themeColor;
      margin-right: $sizeSmall;
    }
  }
}