.profile-form{
  h2{
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: $sizeSmall;
  }
  input{
    border-radius: 0;
    margin-bottom: $sizeSmall;
    border: 0;
    padding-left: 0;
    color: $themeBlack;
    box-shadow: none;
    &:hover{
      background-color: $themeLightGray;
    }
    &[readonly]{
      background-color: $themeWhite;
    }
  }
  button {
    @include semi-rounded-div($sizeMedium, $borderSmall);
    background-color: $themeWhite;
    border-color: $themeColor;
    width: 95%;
    color: $themeColor;
    text-transform: uppercase;
    font-size: 1.2em;
    line-height: 1em;
    text-align: center;
    &:hover {
      color: $themeWhite;
      background-color: $themeColor;
    }
    @media #{$md}{
      width: 100%;
    }
    @media #{$lg}{
      width: 100%;
    }
    @media #{$xlg}{
      width: 95%;
    }
  }
  .button-row {
    width: 100%;
    margin-top: $sizeLarge;
    @media #{$md}{
      margin-top: $sizeMedium;
    }
  }
  .button-side-text {
    height: $sizeMedium + 20;
    padding: $sizeSmall/2 0;
    a {
      color: $themeColor;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}