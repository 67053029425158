.barcode-viewport{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .9);
  z-index: 9999;
  video{
    margin: auto;
    display: block;
    height: 100%;
  }
  .scanner-message{
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, .9);
    position: absolute;
    bottom: 0;
    color: $themeColor;
    font-size: 1.4em;
    padding: $sizeSmall 0;
  }
  .close-icon-wrapper{
    font-size: 1.8em;
    position: absolute;
    top: $sizeSmall;
    right: $sizeSmall;
    z-index: 2;
  }
}

.barcode{
  cursor: pointer;
  background-color: $themeColor;
  color: $themeWhite;
  margin: auto auto $sizeMedium auto;
  text-align: center;
  padding: $sizeSmall 0;
  div{
    font-size: .8em;
  }
}

@keyframes beam {
  50% {
    opacity: 0;
  }
}

@keyframes scanning {
  50% {
    top: 75%;
  }
}

.laser {
  width: 75%;
  margin-left: 12.5%;
  background-color: $themeColor;
  height: 1px;
  position: absolute;
  top: 25%;
  box-shadow: 0 0 4px $themeColor;
  animation: scanning 2s infinite;
}

.diode {
  animation: beam .01s infinite;
}

.barcode-item{
  margin-bottom: $sizeMedium;
  span{
    display: block;
    padding: 0;
    margin-bottom: $sizeSmall;
    @media #{$sm}{
      padding: 0 0 0 $sizeSmall;
      margin-bottom: 0;
    }
  }
  text-align: center;
  @media #{$sm}{
    text-align: left;
    margin-bottom: $sizeSmall;
  }
  button{
    width: 100%;
    @media #{$sm}{
      width: initial;
    }
  }
}

.barcode-remove{
  margin-top: $sizeSmall;
}