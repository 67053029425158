.controls-row{
  margin-bottom: $sizeSmall * 2;
  label{
    color: $themeColor;
    font-size: .9em;
    font-weight: normal;
    margin-bottom: 0;
    span{
      display: inline-block;
      margin-left: $sizeSmall;
      position: absolute;
    }
  }
  .controls-row-wrapper{
    .switch-wrapper{
      width: 160px;
    }
    @media #{$sm}{
      @include flexbox();
    }
    &.has-switch{
      .filters-wrapper{
        width: 100%;
        @media #{$sm}{
          width: calc(100% - 160px);
        }
      }
    }
    .filters-wrapper{
      width: 100%;
      @media #{$sm}{
        @include flexbox();
      }
      button{
        margin: $sizeSmall auto;
        display: block;
        width: 100%;
        @media #{$sm}{
          width: auto;
          display: initial;
          margin: 0 $sizeMedium 5px 0;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
      @media #{$sm}{
      > div:last-of-type{
        align-content: flex-end;
        margin-left: auto;
      }
      }
      &.filter-categories{
        > div:first-of-type{
          padding-right: $sizeSmall;
        }
        button{
          margin-right: $sizeSmall;
        }
      }
    }
  }
}