@keyframes bounceInRight {
  from,
  30%,
  80%,
  to {
    animation-timing-function: ease-in-out;
  }

  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  30% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  80% {
    opacity: 1;
    transform: translate3d(-30px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

//renamed to fake name because of shity toastr plugin with hardcoded animation name

.shake, .fadeIn {
  animation-name: bounceInRight;
}