.main-page{
  margin-left: 50px;
  @media #{$sm}{
    margin-left: 230px;
  }
}

.main-content{
  padding: $sizeSmall*2;
  @media #{$sm}{
    padding: $sizeMedium;
  }
}