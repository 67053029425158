.animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

body {
  font-family: "Segoe UI Semilight", "Segoe UI Light", Calibri, sans-serif;
  color: $themeBlack;
  background-color: $themeWhite;
  min-width: 320px;
  @media #{$xxs}{
    overflow-x: scroll;
  }
}

div > :not(p):not(span):not(i), *:hover {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
}

a {
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

button {
  -webkit-appearance: none !important;
  &:focus {
    outline: none !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.clear {
  clear: both;
}

.block {
  display: block;
}

.label{
  font-weight: normal;
}

.color-danger{
  color: $themeColor
}


.form-control:focus {
  border-color: $themeColorRGBA;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .055), 0 0 5px $themeColorRGBA;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .055), 0 0 5px $themeColorRGBA;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: $themeLightGray !important;
  transition: background-color 5000s ease-in-out 0s;
}

h2{
  padding-left: 15px;
  margin-bottom: $sizeMedium;
}


